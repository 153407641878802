@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  font-size : 100%;
  @apply scroll-smooth;
}

html::-webkit-scrollbar{
  @apply  w-1 rounded-sm;
}
html::-webkit-scrollbar-thumb{
  @apply absolute w-1 rounded-md bg-primary/30;
}
@font-face {
  font-family: "Double Plus";
  src: url(./fonts/Doubleplus.otf);
}

*, *::after{
  @apply transition-all duration-[50ms] appearance-none outline-none;
}
body{
  @apply overflow-x-hidden min-h-screen ;
}

.container{
  @apply mx-auto px-4;
}

.title-1{
  @apply font-semibold font-doublePlus text-3xl lg:text-4xl xl:text-7xl xl:font-bold;
}
.title-2{
  @apply font-semibold text-xl lg:text-3xl;
}